<template>
  <div class="row position-relative" style="height: 50vh">
    <div class="card-preloader d-flex flex-column align-items-center justify-content-center bg-transparent">
      <div class="spinner-border text-primary" role="status">
      </div>
      <p class="mt-2">Загрузка информации...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-preloader"
}
</script>

<style scoped>

</style>