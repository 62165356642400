import store from "@/state/store";

export function hasPermission(key) {
    const permissions = store.getters['auth/user']?.permissions || [];
    for (const id in permissions) {
        const perm = permissions[id];

        if (perm === key) {
            return true;
        }
    }

    return false;
}