<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="text-center pt-4">
        <div class="">
          <img src="@/assets/images/error.svg" alt="" class="error-basic-img move-animation">
        </div>
        <div class="mt-n4">
          <h1 class="display-1 fw-medium">Ненаход</h1>
          <h3 class="text-uppercase">Извините, но пользователь не найден 😭</h3>
          <p class="text-muted mb-4">Попробуйте воспользоваться поиском в шапке сайта!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-not-found"
}
</script>

<style scoped>

</style>